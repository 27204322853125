<template>
  <div class="post-detail wrapper page-wrap" v-if="post">
    <a-spin :spinning="loading">
      <post-user :data="post"></post-user>

      <div class="font-20 text-black mt-20">{{ post.post_title }}</div>
      <div class="flex">
        <img
          v-for="(item, index) in getImgSrc(post.post_image)"
          :src="item"
          alt=""
          :key="index"
          class="post-image"
        />
      </div>

      <div class="flex flex-between post-handle text-black font-18">
        <div class="flex" @click="updatePostFavourData">
          <HeartFilled v-if="post.post_hasfavour" class="icon icon-filled" />
          <HeartOutlined v-else class="icon" />
          <span>点赞</span>
        </div>
        <div class="v-line">|</div>
        <div class="flex" @click="sendPost">
          <MessageOutlined class="icon" />
          <span>评论</span>
        </div>
      </div>
      <div style="padding-bottom: 50px">
        <div class="line"></div>
        <div class="comment-title">评论（{{ post.child.length }}）</div>
        <div class="comment-box" v-if="post.child?.length > 0">
          <div
            v-for="(item, index) in post.child"
            :key="index"
            class="comment-item"
          >
            <post-user :data="item" :isComment="isComment"></post-user>
          </div>
        </div>
      </div>
      <post-popup
        v-model:visible="visible"
        :fatherPostId="postId"
        :postData="post"
        @success="sendSuccess"
      ></post-popup>
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch
} from "@vue/runtime-core";
import {
  getPostData,
  insertPostFavourData,
  deletePostFavourData
} from "@/api/main.js";
import { getUserId } from "@/utils/storeData.js";
import { message } from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import { encode, decode } from "js-base64";
import url from "@/utils/url.js";
import {
  HeartOutlined,
  HeartFilled,
  MessageOutlined
} from "@ant-design/icons-vue";
import PostUser from "./components/postUser.vue";
import PostPopup from "./components/postPopup.vue";
export default defineComponent({
  name: "PostDetail",
  components: {
    HeartOutlined,
    HeartFilled,
    MessageOutlined,
    PostUser,
    PostPopup
  },
  setup() {
    const route = useRoute();
    const pageData = reactive({
      post: null,
      postId: null,
      loading: true,
      isComment: true,
      user_id: computed(() => getUserId()),
      visible: false
    });
    const getPostDataFun = _postId => {
      getPostData({ post_id: _postId, user_id: pageData.user_id })
        .then(res => {
          if (res.code == 200) {
            pageData.post = res.data.post;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const getImgSrc = e => {
      if (e) {
        return e.split(",");
      } else {
        return null;
      }
    };
    const insertPostFavourDataFun = () => {
      insertPostFavourData({
        post_id: pageData.postId,
        user_id: pageData.user_id
      })
        .then(res => {
          if (res.code == 200) {
            getPostDataFun(pageData.postId);
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        });
    };
    const deletePostFavourDataFun = () => {
      deletePostFavourData({
        post_id: pageData.postId,
        user_id: pageData.user_id
      })
        .then(res => {
          if (res.code == 200) {
            getPostDataFun(pageData.postId);
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        });
    };
    const updatePostFavourData = () => {
      if (!pageData.user_id) {
        url.navigateTo("/login");
        return;
      }
      if (pageData.post.post_hasfavour) {
        deletePostFavourDataFun();
      } else {
        insertPostFavourDataFun();
      }
    };
    const sendPost = () => {
      if (pageData.user_id) {
        pageData.visible = true;
      } else {
        url.navigateTo("/login");
      }
    };
    const sendSuccess = () => {
      getPostDataFun(pageData.postId);
    };
    watch(
      () => route.query,
      val => {
        if (route.path == "/post/detail") {
          let query = decode(val?.t).split(",");
          if (query[0]) {
            pageData.loading = true;
            pageData.postId = query[0];
            getPostDataFun(query[0]);
          } else {
            pageData.loading = false;
            pageData.post = null;
            message.error("暂无信息");
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      ...toRefs(pageData),
      getImgSrc,
      updatePostFavourData,
      sendSuccess,
      sendPost
    };
  }
});
</script>
<style lang="less" scoped>
.post-detail {
  margin-top: 40px;
  .post-image {
    display: block;
    width: 240px;
    height: 240px;
    margin: 10px 10px 0 10px;
  }
  .list-top {
    border-bottom: 1px dashed rgba(124, 124, 124, 0.6);
    padding: 10px 0;
    img {
      width: 60px;
      height: 60px;
      background-color: #ffffff;
      border-radius: 60px;
      margin-right: 20px;
    }
    .user-name {
      color: #212531;
      font-size: 20px;
    }
    .enterprise {
      color: #212531;
      opacity: 0.6;
      font-size: 14px;
      margin-left: 20px;
      background-color: #f3f5f8;
      border-radius: 11px;
      padding: 0 10px;
    }
    .post-time {
      color: #212531;
      opacity: 0.6;
      font-size: 16px;
      margin-top: 3px;
    }
  }
  .post-handle {
    margin-top: 60px;
    margin-bottom: 20px;
    background-color: #f3f5f8;
    .icon-filled {
      color: #e60012;
    }
    > div {
      width: 45%;
      height: 60px;
      border-radius: 5px;
      justify-content: center;
      cursor: pointer;
      .icon {
        margin-right: 10px;
      }
    }
    .v-line {
      width: 10%;
      text-align: center;
      line-height: 60px;
      color: #999999;
    }
  }
  .comment-title {
    position: relative;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 20px;
    color: #212531;
    border-bottom: 1px solid #f6f8f9;
    &:before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: #1276cb;
      border-radius: 10px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .comment-box {
    padding: 0 20px;
    .comment-item {
    }
  }
}
</style>
